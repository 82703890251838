import "./pages/works.js"
import "./pages/members.js"
import "./pages/recruitment.js"
import "./pages/news.js"
import "./components/contentBlockGallery.js"
import "./pages/homeRecruit.js"
import "./pages/homeAboutUs.js"
import "./pages/homeProjectExamaple.js"
import "./pages/homeInterview.js"
import "./pages/homeMember.js"
import "./pages/homeNews.js"
import "./pages/homeHero.js"
import "./pages/aboutUsPage.js"
import "./pages/aboutUsVisionMission.js"
import "./pages/aboutUsPhilosophy.js"
import * as easing from './utils/easing.esm.mjs';

import { currentPagePath, delay, disableTransition, mediaQuery } from './common.js';


/**
 * partials
 */

/*** hamburger-menu ***/
/***
 * ハンバーガーメニューの開閉
 ***/

const hamburgerTrigger = document.querySelector('[aria-controls=navBar]');
const hamburgerTarget = document.getElementById('navBar');

// 開閉を切り替えるハンドラ
const handleToggleHamburger = (event) => {
  event.preventDefault();
  event.stopPropagation();
  hamburgerTrigger.setAttribute('aria-expanded', hamburgerTrigger.getAttribute('aria-expanded') === 'false');
  hamburgerTarget.setAttribute('aria-hidden', hamburgerTarget.getAttribute('aria-hidden') === 'false');
}

hamburgerTrigger.addEventListener('click', handleToggleHamburger);

/*** smooth-scroll ***/
/***
 * アンカーリンク全般にスムーススクロール
 * サイトヘッダー分の調整値を「getComputedStyle」によりCSS（カスタムプロパティ記法）より参照
 ***/

const siteHeaderElement = document.querySelector('.siteHeader');
const easingFunction = easing.easeOutQuint;
const animationDuration = 1000;

// サイトヘッダーの高さを取得
const getSiteHeaderHeight = () => {
  const siteHeaderComputedStyle = window.getComputedStyle(siteHeaderElement);
  const siteHeaderHeightCssVariableName = mediaQuery.matches ? '--scroll-margin-top-desktop' : '--scroll-margin-top-mobile';
  const siteHeaderHeight = parseInt(siteHeaderComputedStyle.getPropertyValue(siteHeaderHeightCssVariableName));
  return siteHeaderHeight;
};

// スムーススクロールの経過処理
const trackSmoothScrollProgress = (target, duration) => {
  const siteHeaderHeight = getSiteHeaderHeight();
  const startPosition = window.pageYOffset || document.documentElement.scrollTop;
  const targetPosition = document.querySelector(target).offsetTop;
  const targetDistance = targetPosition - startPosition - siteHeaderHeight;
  let startTimestamp = null;

  const animationSmoothScroll = (timestamp) => {
    if (startTimestamp === null) startTimestamp = timestamp;
    const elapsed = timestamp - startTimestamp;
    const progress = Math.min(elapsed / duration, 1);
    const ease = easingFunction(progress);

    window.scrollTo({
      top: startPosition + targetDistance * ease,
      // behavior: 'smooth', // easeから更に緩急をつけたい場合
    });

    if (elapsed < duration) window.requestAnimationFrame(animationSmoothScroll);
  };

  window.requestAnimationFrame(animationSmoothScroll);
};

// スムーススクロールを行うハンドラ
const handleSmoothScroll = (event) => {
  event.preventDefault();
  event.stopPropagation();
  const target = event.currentTarget.getAttribute('href');
  trackSmoothScrollProgress(target, animationDuration);
}

const anchorLinks = document.querySelectorAll('a[href^="#"]');
anchorLinks.forEach((link) => link.addEventListener('click', handleSmoothScroll));
